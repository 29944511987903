import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
		<path d="M4030 8355 c-158 -15 -183 -19 -315 -52 -474 -120 -885 -351 -1208
-678 -151 -153 -316 -371 -404 -535 -95 -176 -120 -226 -136 -270 -8 -25 -32
-88 -52 -140 -33 -86 -69 -223 -94 -355 -45 -237 -58 -435 -42 -630 13 -160
40 -341 75 -496 8 -36 33 -115 56 -175 23 -60 52 -139 66 -175 13 -37 36 -89
52 -115 15 -27 36 -65 47 -85 68 -123 90 -161 130 -219 65 -96 98 -138 190
-246 124 -144 304 -306 466 -418 104 -73 375 -226 399 -226 6 0 29 -9 53 -19
152 -70 217 -92 377 -125 69 -15 154 -33 190 -41 126 -27 264 -37 460 -32 213
5 311 15 440 45 47 11 121 28 165 37 94 20 358 121 465 178 182 96 168 88 358
216 31 21 81 62 111 90 30 28 57 51 61 51 19 0 270 262 313 326 15 22 36 50
46 60 36 39 92 126 85 132 -4 4 -9 1 -11 -6 -10 -27 -249 -277 -343 -358 -67
-57 -201 -156 -206 -151 -3 3 14 22 36 43 207 193 388 399 449 514 17 30 43
69 59 85 16 17 35 42 42 55 7 14 29 48 50 75 39 52 130 223 130 244 0 8 -51
11 -202 9 l-201 -3 -57 -95 c-32 -52 -73 -113 -92 -135 -19 -22 -40 -51 -47
-64 -27 -49 -301 -310 -359 -340 -15 -8 -29 -17 -32 -21 -14 -18 -148 -99
-254 -152 -255 -128 -596 -209 -876 -207 -140 0 -395 37 -500 72 -25 8 -85 28
-135 43 -119 36 -319 136 -452 226 -59 40 -163 124 -230 186 -68 62 -123 110
-123 107 0 -42 228 -276 374 -385 91 -66 202 -140 212 -140 2 0 41 -21 86 -46
105 -60 353 -155 493 -189 240 -59 746 -61 960 -4 22 6 56 14 75 18 118 27
430 155 502 205 31 22 45 20 23 -4 -10 -11 -22 -20 -27 -20 -7 0 -91 -53 -123
-78 -5 -4 -41 -24 -80 -44 -174 -91 -446 -179 -645 -210 -87 -13 -567 -17
-680 -5 -206 22 -549 138 -738 250 -35 20 -65 37 -67 37 -11 0 -197 130 -260
181 -79 64 -296 280 -323 321 -9 14 -38 53 -65 86 -26 33 -47 65 -47 71 0 6
-4 11 -8 11 -5 0 -15 12 -23 28 -7 15 -25 45 -39 67 -83 128 -230 476 -230
544 0 11 -4 22 -9 25 -5 3 -11 23 -14 43 -3 21 -11 63 -17 93 -27 144 -34 231
-34 430 0 227 13 355 54 520 6 25 15 61 19 80 13 55 61 194 72 205 5 5 9 15 9
22 0 8 28 71 62 141 67 137 183 319 267 420 82 98 241 259 301 305 30 23 57
44 60 47 54 55 295 201 445 270 39 17 78 36 87 41 10 5 27 9 38 9 11 0 20 4
20 9 0 5 12 11 28 14 15 2 67 17 115 31 84 25 196 50 352 76 99 17 501 8 625
-14 132 -23 334 -75 405 -106 11 -4 47 -18 80 -31 64 -24 212 -99 310 -157
146 -86 415 -309 415 -343 0 -15 -52 16 -85 51 -26 28 -217 160 -231 160 -3 0
-24 13 -47 29 -23 15 -53 32 -67 36 -14 4 -45 18 -70 31 -93 49 -212 94 -317
119 -59 14 -133 32 -163 39 -133 33 -311 49 -485 43 -93 -3 -199 -11 -235 -17
-117 -21 -391 -92 -454 -117 -110 -45 -330 -154 -366 -183 -19 -15 -50 -35
-69 -44 -56 -29 -224 -171 -330 -279 -100 -102 -148 -157 -136 -157 13 0 113
80 144 116 19 22 49 48 67 60 19 11 49 34 66 50 51 46 276 180 348 207 36 14
106 41 155 61 112 46 212 72 323 86 48 6 114 15 148 21 79 12 320 12 398 -1
33 -5 110 -16 169 -25 59 -9 142 -27 185 -41 143 -47 385 -153 431 -189 14
-11 49 -33 78 -50 123 -70 354 -279 449 -405 22 -30 58 -80 81 -110 22 -30 51
-75 63 -100 42 -84 29 -80 252 -80 109 0 198 4 198 9 0 16 -110 238 -159 319
-17 29 -31 55 -31 58 0 11 -153 232 -235 339 -49 64 46 -20 126 -111 14 -16
36 -38 49 -49 l23 -20 -13 25 c-19 36 -136 188 -185 240 -24 25 -58 61 -76 81
-98 104 -332 295 -419 342 -19 11 -55 34 -80 52 -25 18 -90 55 -145 82 -55 27
-109 55 -120 61 -26 15 -133 58 -187 76 -127 41 -251 77 -313 91 -38 9 -79 18
-90 21 -11 2 -92 11 -180 19 -188 17 -339 17 -525 0z m2110 -867 c0 -14 -19 1
-31 25 -12 22 -12 22 10 3 11 -11 21 -23 21 -28z m28 -163 c-3 -3 -9 2 -12 12
-6 14 -5 15 5 6 7 -7 10 -15 7 -18z"/>
<path d="M6370 7246 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M3007 7092 c-19 -21 -22 -32 -8 -32 5 0 16 11 25 25 19 29 7 34 -17
7z"/>
<path d="M3787 6812 l-28 -29 40 -59 c22 -33 52 -95 67 -139 23 -68 28 -96 28
-185 0 -88 -5 -118 -27 -183 -15 -43 -46 -106 -68 -139 l-41 -60 26 -27 c34
-33 49 -25 97 55 67 109 91 194 97 330 4 102 1 132 -17 199 -25 89 -69 177
-115 231 l-31 36 -28 -30z"/>
<path d="M2769 6788 c-162 -223 -162 -553 0 -775 34 -47 50 -52 74 -25 23 25
21 37 -12 85 -34 51 -77 155 -92 228 -26 121 12 304 85 415 41 61 43 74 18 97
-28 25 -39 21 -73 -25z"/>
<path d="M2890 6658 c-67 -117 -87 -257 -55 -383 17 -64 58 -145 85 -170 16
-15 20 -14 44 10 30 30 32 38 12 54 -22 18 -54 102 -65 168 -14 88 1 174 44
257 l35 70 -22 23 c-12 13 -27 23 -35 23 -7 0 -26 -24 -43 -52z"/>
<path d="M3660 6685 c-24 -25 -24 -26 -6 -53 45 -70 60 -117 64 -207 5 -102
-12 -174 -53 -237 -31 -47 -31 -49 1 -77 26 -22 27 -22 44 -4 29 30 70 112 85
171 19 73 19 173 -1 247 -18 71 -75 179 -95 183 -8 1 -26 -9 -39 -23z"/>
<path d="M3037 6566 c-52 -79 -60 -208 -17 -298 24 -53 40 -58 72 -28 21 20
22 21 5 68 -22 59 -22 134 -1 189 17 43 16 44 -6 68 -29 31 -34 31 -53 1z"/>
<path d="M3540 6565 c-24 -25 -24 -26 -7 -73 22 -60 22 -124 0 -184 -17 -47
-16 -48 5 -68 33 -31 48 -24 73 33 34 78 33 183 -3 260 -15 31 -31 57 -36 57
-5 0 -19 -11 -32 -25z"/>
<path d="M3255 6541 c-35 -15 -75 -62 -85 -102 -32 -120 100 -231 208 -175
116 61 113 229 -6 277 -28 11 -89 11 -117 0z"/>
<path d="M4196 6278 c-9 -13 -16 -43 -16 -69 0 -75 10 -79 188 -79 88 0 153
-4 157 -10 3 -5 6 -164 5 -353 0 -338 0 -344 21 -371 21 -27 24 -28 105 -24
50 2 88 8 96 16 10 10 14 97 18 375 l5 362 163 5 c186 6 192 8 192 87 0 31 -6
48 -22 63 -21 19 -33 20 -459 20 l-438 0 -15 -22z"/>
<path d="M5971 6286 c-8 -9 -10 -132 -9 -436 l3 -423 40 -14 c192 -67 446 -63
565 10 113 69 171 232 140 389 -23 119 -75 193 -175 246 -46 25 -59 27 -170
27 -81 0 -127 -4 -143 -13 -41 -23 -50 -3 -51 110 -1 55 -4 104 -7 109 -9 15
-180 11 -193 -5z m468 -383 c34 -33 40 -46 46 -96 10 -81 1 -186 -19 -224 -29
-57 -57 -68 -174 -68 -78 0 -105 4 -112 14 -10 18 -13 362 -3 377 14 20 67 32
145 33 76 1 78 0 117 -36z"/>
<path d="M6840 6280 c-7 -13 -10 -161 -8 -455 3 -374 5 -437 18 -445 8 -5 52
-10 97 -10 71 0 84 3 93 19 11 21 15 867 4 895 -5 13 -24 16 -100 16 -83 0
-94 -2 -104 -20z"/>
<path d="M7222 6283 c-4 -10 -7 -215 -7 -456 0 -378 2 -439 15 -447 20 -13
156 -13 181 0 18 10 19 26 19 453 0 331 -3 446 -12 455 -8 8 -45 12 -100 12
-75 0 -90 -3 -96 -17z"/>
<path d="M3195 6021 c-25 -80 -49 -153 -52 -161 -7 -22 -57 -181 -188 -595
-64 -203 -130 -411 -146 -462 -17 -50 -28 -97 -24 -102 8 -14 1051 -15 1060
-1 3 5 -14 71 -39 147 -24 76 -67 212 -96 303 -76 243 -156 497 -206 650 -23
74 -59 187 -79 251 l-36 117 -74 0 -74 0 -46 -147z m159 -32 c14 -48 26 -93
26 -100 0 -12 -57 -69 -69 -69 -12 0 -61 59 -61 72 0 17 46 174 56 189 11 18
21 -2 48 -92z m-114 -224 c9 -11 6 -19 -16 -42 -23 -22 -29 -25 -32 -12 -4 20
15 69 27 69 4 0 14 -7 21 -15z m194 -23 c12 -45 -1 -53 -30 -18 -20 22 -22 31
-13 42 17 21 32 12 43 -24z m-21 -149 c48 -47 87 -92 87 -99 0 -17 -169 -184
-186 -184 -17 0 -199 179 -190 188 58 62 183 182 191 182 6 0 50 -39 98 -87z
m-297 -221 c10 -10 46 -46 78 -79 l59 -61 -149 -147 c-81 -81 -150 -142 -152
-136 -3 9 16 74 97 328 27 86 38 113 45 113 2 0 12 -8 22 -18z m500 -210 c37
-116 65 -215 61 -218 -3 -4 -72 60 -152 141 l-146 148 33 35 c75 79 123 121
129 114 4 -4 38 -103 75 -220z m-106 -186 c99 -99 180 -183 180 -188 0 -4
-169 -8 -375 -8 -229 0 -375 4 -375 9 0 17 364 372 377 369 8 -2 94 -84 193
-182z"/>
<path d="M5303 6081 c-63 -16 -143 -63 -178 -103 -114 -134 -106 -396 16 -517
137 -137 458 -132 597 10 57 59 75 111 80 239 3 99 1 123 -17 171 -30 79 -80
133 -159 171 -62 30 -75 32 -187 34 -66 2 -134 -1 -152 -5z m227 -154 c61 -40
75 -78 75 -197 -1 -154 -35 -211 -133 -226 -65 -10 -131 9 -164 47 -68 80 -63
293 9 361 36 33 46 36 119 37 48 1 68 -4 94 -22z"/>
<path d="M7558 6084 c-30 -9 -26 -31 79 -357 58 -180 140 -278 246 -293 29 -4
51 -13 54 -21 10 -25 -34 -101 -68 -118 -25 -13 -46 -15 -100 -10 -49 5 -74 3
-84 -5 -16 -14 -21 -96 -6 -118 12 -20 204 -36 260 -22 132 33 174 107 281
490 40 140 82 292 96 338 13 45 21 88 18 96 -14 36 -175 30 -199 -8 -8 -12
-39 -111 -69 -221 -30 -110 -59 -212 -65 -227 -21 -56 -84 -23 -128 67 -18 36
-37 93 -100 300 -29 98 -39 106 -124 110 -41 2 -82 2 -91 -1z"/>
<path d="M8465 6083 c-11 -3 -23 -10 -27 -17 -4 -6 -8 -160 -8 -343 0 -386
-11 -353 119 -353 69 0 82 3 91 19 6 12 10 109 10 245 l0 224 61 41 c51 34 70
41 110 41 61 0 91 -14 115 -53 17 -29 19 -57 22 -262 2 -141 7 -235 14 -242 7
-9 39 -13 94 -13 122 0 116 -15 112 269 -3 207 -6 242 -23 282 -31 73 -65 110
-130 141 -86 40 -185 40 -271 -1 -34 -16 -65 -34 -68 -40 -12 -20 -36 -12 -36
12 0 41 -14 49 -91 52 -41 2 -83 1 -94 -2z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
